import * as React from "react";
import { Link } from "gatsby";
import Layout from "src/components/layout";
import Button from "src/components/button.js";
import ContactForm from "src/components/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteRight,
  faMapMarkerAlt,
  faPhoneAlt,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
// import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import Carousel from "src/components/Carousel/Carousel";
import ArrowWhite from "src/images/arrow-white.svg";
import logoWhite from "src/images/logo-white.svg";

// const getSVGURI = ({ prefix, iconName, icon }, color) =>
//   `data:image/svg+xml;base64,${btoa(`<svg data-prefix="${prefix}" data-icon="${iconName}"
//     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
//     <path fill="${color || "currentColor"}" d="${icon[4]}"></path>
//     </svg>`)}`;

const IndexPageComponent = ({
  bannerText,
  pageName,
  pageTitle,
  location,
  copy,
  lang,
}) => {
  const prefix = lang === "en" ? "" : `/${lang}`;

  const testimonialFrames = copy.testimonials.items.map((testimonial, key) => (
    <div key={key} className="testimonial-frame leading-7">
      <p className="pt-8">&ldquo;{testimonial.text}&rdquo;</p>
      <p className="pt-2 lg:pt-8 italic">{testimonial.author}</p>
    </div>
  ));

  return (
    <Layout
      bannerText={bannerText}
      pageName={pageName}
      pageTitle={pageTitle}
      location={location}
      lang={lang}
    >
      <article className="container inner-content mx-auto lg:flex lg:flex-row">
        <iframe
          loading="lazy"
          src="//fast.wistia.net/embed/iframe/vyeo0atzoo?videoFoam=true"
          className="wistia_embed w-5/6 mx-auto lg:mx-4 mt-4 lg:mt-0"
          name="wistia_embed"
          allowFullScreen
          title="Spetner Intro Video"
          style={{ aspectRatio: "16/9" }}
        ></iframe>
        <section className="lg:w-full m-8">
          <h2 className="mb-4">{copy.video.title}</h2>
          <p className="leading-7">{copy.video.text}</p>
        </section>
      </article>
      <div className="container inner-content mx-auto flex justify-center mt-10">
        <Button
          href={`${prefix}/get-a-quote`}
          className="py-4 px-8 text-2xl"
          arrow
        >
          {copy.quoteButton}
        </Button>
      </div>
      <div className="bg-dots">
        <article className="container inner-content w-full mx-auto lg:flex lg:flex-row  gap-10 mt-20 py-4 lg:py-20 text-center">
          {copy.homeMainItems.map((item, key) => (
            <section
              key={key}
              className="lg:w-1/2 my-16 lg:my-0 bg-white rounded-3xl overflow-hidden flex flex-col space-y-4 px-4
          before:bg-blue-pale before:w-32 before:h-32 before:block before:mx-auto before:top-0 before:-mt-20 before:rounded-full
          after:bg-blue-pale after:w-32 after:h-32 after:block after:mx-auto after:bottom-0 after:-mb-20 after:rounded-full"
            >
              <h2 className="mt-8">{item.title}</h2>
              <p className="flex-1 leading-7 mx-4 md:mx-14">{item.text}</p>
              <div className="mb-8 mx-auto">
                <Button
                  href={`${prefix}${item.href}`}
                  className="mx-auto"
                  invert
                  arrow
                >
                  {copy.readMore}
                </Button>
              </div>
              <div>&nbsp;</div>
            </section>
          ))}
        </article>

        <div className="hidden md:block bg-testimonial-banner bg-cover bg-top pt-20 pb-40 rtl-flip px-20">
          <article className="bg-white rounded-2xl mx-auto inner-content text-center mt-20 py-8 bg-opacity-70 h-80">
            <h2>
              <span className="text-5xl align-middle mx-3">
                <FontAwesomeIcon icon={faQuoteRight} className="rtl-flip" />
              </span>
              {copy.testimonials.title}
            </h2>
            <Carousel
              className="h-36 px-16"
              options={{
                buttons: {
                  left: {
                    content: (
                      <img
                        alt=""
                        src={ArrowWhite}
                        style={{
                          transform: "scaleX(-1) translateY(-50%)",
                          position: "absolute",
                          left: "0px",
                          right: "0px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          top: "50%",
                        }}
                      />
                    ),
                    style: { transform: "translateX(-50%)" },
                  },
                  right: {
                    content: (
                      <img
                        alt=""
                        src={ArrowWhite}
                        style={{
                          transform: "translateY(-50%)",
                          position: "absolute",
                          left: "0px",
                          right: "0px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          top: "50%",
                        }}
                      />
                    ),
                    style: { transform: "translateX(50%)" },
                  },
                  style: {
                    height: "3rem",
                    textAlign: "center",
                    aspectRatio: "1/1",
                    borderRadius: "100%",
                    backgroundColor: "#004581",
                    opacity: 1,
                  },
                },
              }}
            >
              {testimonialFrames}
            </Carousel>
            <div className="container inner-content mx-auto flex justify-center mt-10 mb-20">
              <Button
                href={`${prefix}/testimonials`}
                className="py-4 mt-4 px-8 text-2xl mx-auto"
                arrow
              >
                {copy.readMore}
              </Button>
            </div>
          </article>
        </div>
      </div>
      <article className="container inner-content mx-auto text-center mt-20 px-4">
        <h2>{copy.blogPosts.title}</h2>
        <section className="-mx-4 flex flex-wrap lg:mt-16 justify-center">
          {copy.blogPosts.items.map((article, key) => (
            <article
              key={key}
              className="flex flex-col p-4 w-full md:w-2/3 lg:w-1/3 "
            >
              <div className="flex-1 shadow-lg p-8">
                <h3 className="text-blue text-lg font-semibold pb-4">
                  <Link to={`${prefix}${article.href}`}> {article.title}</Link>
                </h3>
                <p>{article.preview}</p>
              </div>
            </article>
          ))}
        </section>
      </article>
      <div className="container inner-content mx-auto flex justify-center mt-10 mb-20">
        <Button
          href={`${prefix}/blog`}
          className="py-4 px-8 text-2xl mx-auto"
          invert
          arrow
        >
          {copy.readMore}
        </Button>
      </div>

      <article className="bg-quote-banner bg-cover bg-no-repeat  bg-bottom pt-20 pb-40 text-center z-10 relative">
        <div className="inner-content mx-auto flex flex-col">
          <h2 className="text-white text-4xl mt-20 mb-10 mx-4">
            {copy.insuranceQuotes.title}
          </h2>
          <p className="text-white mx-4">{copy.insuranceQuotes.text}</p>
          <div className="mx-auto">
            <Button
              href={`${prefix}/get-a-quote`}
              className="py-4 text-2xl mx-auto mt-10"
              arrow
            >
              {copy.quoteButton}
            </Button>
          </div>
        </div>
      </article>

      <article className="lg:flex lg:flex-wrap -mt-20">
        <section className="flex flex-col lg:w-7/12 pt-40 text-center">
          <h2 className="text-4xl">{copy.contact.title}</h2>
          <div className="md:w-2/3 md:mx-auto">
            <ContactForm copy={copy.contact} />
          </div>
        </section>
        <section className="lg:w-5/12 pt-40 pb-48 bg-blue-dots bg-blue text-white">
          <div
            className={`grid grid-cols-12 gap-6 w-1/2 ${
              lang === "he" ? "mr-24" : "ml-12 lg:ml-24"
            }`}
            dir="ltr"
          >
            <div className="col-span-1" />
            <div className="col-span-11 pb-4">
              <Link to={`${prefix}/`}>
                <img
                  alt="Spetner Logo"
                  src={logoWhite}
                  className="w-52 inline"
                />
              </Link>
            </div>
            <div className="col-span-2 bg-blue-light inline-block w-12 h-12 py-3 text-center rounded-full">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                color="#fff"
                className="h-4 w-4"
              />
            </div>
            <p className="col-span-10 mx-4">
              SPETNER ASSOCIATES
              <br />
              8630 Delmar Blvd
              <br />
              Suite 100 <br />
              St. Louis, MO 63124
            </p>
            <div className="col-span-2 bg-blue-light inline-block w-12 h-12 py-3 text-center rounded-full">
              <FontAwesomeIcon
                icon={faPhoneAlt}
                color="#fff"
                className="h-4 w-4"
              />
            </div>
            <p className="col-span-10 self-center mx-4">
              <a href="tel:+13144420000">+1.314.442.0000</a>
            </p>
            <div className="col-span-2 bg-blue-light inline-block w-12 h-12 py-3 text-center rounded-full">
              <FontAwesomeIcon icon={faFax} color="#fff" className="h-4 w-4" />
            </div>
            <p className="col-span-10 self-center mx-4">+1.314.442.0051</p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default IndexPageComponent;
