// extracted by mini-css-extract-plugin
export var carousel = "Carousel-module--carousel--x-E4l";
export var frames = "Carousel-module--frames--BtCAe";
export var nav = "Carousel-module--nav--6BAtp";
export var left = "Carousel-module--left--TyUXi";
export var right = "Carousel-module--right--MqOW6";
export var chevron = "Carousel-module--chevron--S2+AN";
export var frame = "Carousel-module--frame--WqhrY";
export var current = "Carousel-module--current--5QBDz";
export var enteringFromLeft = "Carousel-module--enteringFromLeft--FTS+U";
export var enteringFromRight = "Carousel-module--enteringFromRight--xloSQ";
export var exitingLeft = "Carousel-module--exitingLeft--S19i2";
export var exitingRight = "Carousel-module--exitingRight--7MHuH";
export var exitedLeft = "Carousel-module--exitedLeft--laWdu";